<script>
import utilsMixin from '@shared/mixins/utils';
import StatisticCard from '@shared/components/StatisticCard.vue';
import APIAffiliate from '@app/services/API/Affiliate';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [utilsMixin],
  components: { StatisticCard },
  props: {
    affiliateUUID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFetching: false,
    affiliate: null,
  }),
  computed: {
    ...mapState('trainings', {
      trainings: 'list',
    }),
    ...mapState('bundles', {
      bundles: 'list',
    }),
    ...mapGetters('auth', {
      authStore: 'store',
    }),
    commissionRate() {
      const hasRate = (
        this.affiliate.applications.store_amount
        || this.affiliate.applications.store_percentage
      );

      if (!hasRate) {
        const options = this.authStore.affiliation_options;

        return options.default_commission_amount
          ? `${options.default_commission_amount}€`
          : `${options.default_commission_percentage}%`;
      }

      return this.affiliate.applications.store_amount
        ? `${this.affiliate.applications.store_amount}€`
        : `${this.affiliate.applications.store_percentage}%`;
    },
    trainingsByUUID() {
      return this.trainings
        .data
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    bundlesByUUID() {
      return this.bundles
        .data
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    affiliateTrainingsCommissionRate() {
      return this.affiliate
        .applications
        .trainings
        .filter((v) => !!this.trainingsByUUID[v.uuid])
        .map((v) => ({
          uuid: v.uuid,
          slug: this.trainingsByUUID[v.uuid].slug,
          name: this.trainingsByUUID[v.uuid].name,
          status: this.trainingsByUUID[v.uuid].status,
          commissionRate: (v.amount ? `${v.amount}€` : `${v.percentage}%`),
        }));
    },
    affiliateBundlesCommissionRate() {
      return this.affiliate.applications.bundles
        ? this.affiliate
          .applications
          .bundles
          .filter((v) => !!this.bundlesByUUID[v.uuid])
          .map((v) => ({
            uuid: v.uuid,
            slug: this.bundlesByUUID[v.uuid].slug,
            name: this.bundlesByUUID[v.uuid].name,
            status: this.bundlesByUUID[v.uuid].status,
            commissionRate: (v.amount ? `${v.amount}€` : `${v.percentage}%`),
          }))
        : [];
    },
  },
  created() {
    this.isFetching = true;
    Promise.all([
      this.$store.dispatch('trainings/fetch'),
      APIAffiliate.getAffiliate(
        this.authStore.uuid,
        this.affiliateUUID,
      ),
      this.$store.dispatch('bundles/fetch'),
    ])
      .then(([, { data }]) => (this.affiliate = data))
      .finally(() => (this.isFetching = false));
  },
};
</script>

<template>
  <AppCardModal>
    <template #title>
      Fiche affilié
    </template>
    <template #body>
      <b-skeleton v-if="isFetching" height="400" />
      <template v-else-if="affiliate">
        <div class="is-flex mb-3">
          <div>
            <h3 class="title is-5 mb-0">
              {{ affiliate.customer.firstname }}
              {{ affiliate.customer.lastname }}
            </h3>
            <p>
              <strong>{{ affiliate.customer.email }}</strong>
            </p>
          </div>
        </div>

        <p>
          <span
            class="tag"
            :class="{
              'is-success': affiliate.status === 'ACTIVE',
              'is-black': affiliate.status === 'SUSPENDED',
            }"
            v-t="`affiliate.status.${affiliate.status}`"
          />
        </p>

        <p class="mt-3">
          Clé d'affiliation : {{ affiliate.code }}
        </p>

        <p v-if="affiliate.customer.last_login_at">
          Dernière connexion le
          {{ affiliate.customer.last_login_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
        </p>
        <p v-else>
          Jamais connecté
        </p>

        <h4 class="title is-6 mb-1 mt-5">
          Montant des commissions
        </h4>
        <p v-if="affiliate.applications.store">
          {{ commissionRate }}
          sur toutes les formations et tous les packs
        </p>
        <ul v-else>
          <li
            v-for="training in affiliateTrainingsCommissionRate"
            :key="training.uuid"
          >
            {{ training.commissionRate }}
            sur la formation
            <strong>{{ training.name }}</strong>
          </li>
          <li
            v-for="bundle in affiliateBundlesCommissionRate"
            :key="bundle.uuid"
          >
            {{ bundle.commissionRate }}
            sur le pack
            <strong>{{ bundle.name }}</strong>
          </li>
        </ul>

        <h4 class="title is-6 mb-3 mt-5">
          Performances
        </h4>
        <div class="columns is-mobile is-multiline">
          <div class="column is-6">
            <StatisticCard class="has-background-light">
              <template #title>
                Visites
              </template>
              <template #default>
                {{ affiliate.total_visits }}
              </template>
              <template #tooltipContent>
                <span v-t="'affiliate.stat_description.total_visits'" />
              </template>
            </StatisticCard>
          </div>
          <div class="column is-6">
            <StatisticCard class="has-background-light">
              <template #title>
                Ventes
              </template>
              <template #default>
                {{ affiliate.total_sales }}
              </template>
              <template #tooltipContent>
                <span v-t="'affiliate.stat_description.total_sales'" />
              </template>
            </StatisticCard>
          </div>
          <div class="column is-6">
            <StatisticCard class="has-background-light">
              <template #title>
                Taux de conversion
              </template>
              <template #default>
                {{
                  [affiliate.total_sales, affiliate.total_visits] | toPercentage
                }}%
              </template>
              <template #tooltipContent>
                <span v-t="'affiliate.stat_description.total_conversion'" />
              </template>
            </StatisticCard>
          </div>
          <div class="column is-6">
            <StatisticCard class="has-background-light">
              <template #title>
                Total des commissions
              </template>
              <template #default>
                {{ affiliate.total_amount | formatPrice }}
              </template>
              <template #tooltipContent>
                <span v-t="'affiliate.stat_description.total_amount'" />
              </template>
            </StatisticCard>
          </div>
          <div class="column is-6">
            <StatisticCard class="has-background-light">
              <template #title>
                Montant disponible
              </template>
              <template #default>
                {{ affiliate.total_available | formatPrice }}
              </template>
              <template #tooltipContent>
                <span v-t="'affiliate.stat_description.total_available'" />
              </template>
            </StatisticCard>
          </div>
          <div class="column is-6">
            <StatisticCard class="has-background-light">
              <template #title>
                Montant en attente
              </template>
              <template #default>
                {{ affiliate.total_pending | formatPrice }}
              </template>
              <template #tooltipContent>
                <span v-t="'affiliate.stat_description.total_pending'" />
              </template>
            </StatisticCard>
          </div>
        </div>
      </template>
      <p v-else>
        L'affilié n'a pas pu être trouvé.
      </p>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Annuler
      </b-button>
      <b-button
        type="is-primary is-custom"
        :disabled="!affiliate"
        @click="$emit('edit', affiliate)"
      >
        Modifier
      </b-button>
    </template>
  </AppCardModal>
</template>
