<script>
import AffiliateFullCard from '@app/components/AffiliateFullCard.vue';

export default {
  components: { AffiliateFullCard },
};
</script>

<template>
  <b-modal
    :can-cancel="false"
    hasModalCard
    active
  >
    <AffiliateFullCard
      :affiliateUUID="$route.params.uuid"
      @edit="$router.push({ name: 'affiliate_edit' })"
      @cancel="$router.push({ name: 'affiliates' })"
    />
  </b-modal>
</template>
